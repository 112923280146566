var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_common_sub_navigation_index d-flex align-center flex-wrap",
    },
    [
      _vm._l(_vm.navigation, function (item, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "item_group d-flex align-center" },
            [
              index !== 0
                ? _c("div", { staticClass: "symbol mx-4" }, [_vm._v(" > ")])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "item cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeNavigation({ item, index })
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.itemName(item)))]
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }